import { AbstractCollection } from '../../abstract/Collection';
import { AbstractDocument, Identifiable, Timestampable } from '../../abstract/Document';
import { type Supplier, Suppliers } from '../Suppliers';
import type { IConfiguration } from './Configurations.types';
import { orderBy, where } from 'firebase/firestore';
import type { Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';

export type Namespaces = IConfiguration['data']['namespace'];
export type NamespaceScopes<Namespace extends Namespaces> = Extract<IConfiguration['data'], { namespace: Namespace }>['scope'];
export type NamespaceScoped<Namespace extends Namespaces> = {
  [Scope in NamespaceScopes<Namespace>]: (Omit<IConfiguration, 'data'> & {
    data: Extract<IConfiguration['data'], { namespace: Namespace; scope: Scope }>;
  })[];
};

@Identifiable
@Timestampable
export class Configuration extends AbstractDocument<IConfiguration> {
  readonly collections = {};
}

export class Configurations extends AbstractCollection<Configuration, IConfiguration> {
  static path = 'configurations';

  constructor(document: Supplier) {
    super(document.collection(Configurations.path), Configuration);
  }

  /**
   * Returns an observable of all configurations for the `Leads` namespace, grouped by `scope`.
   */
  get leads() {
    return this.observeAllByNamespace('Leads');
  }

  get supplier() {
    return Suppliers._.getById(this.reference.parent.id);
  }

  /**
   * Generic method to get all configurations for a specific `namespace`, and group them by `scope`.
   *
   * @param namespace The `namespace` of the configurations to get.
   */
  async getAllByNamespace<Namespace extends Namespaces>(namespace: Namespace): Promise<Partial<NamespaceScoped<Namespace>>> {
    const result: Record<string, unknown[]> = {};
    const configurations = await this.query([where('data.namespace', '==', namespace), orderBy('order', 'asc')]).get(true);

    for (const configuration of Object.values(configurations)) {
      if (result[configuration.data.scope] == null) {
        result[configuration.data.scope] = [];
      }

      result[configuration.data.scope].push(configuration);
    }

    return result as Partial<NamespaceScoped<Namespace>>;
  }

  /**
   * Generic method to observe all configurations for a specific `namespace`, and group them by `scope`.
   *
   * @param namespace The `namespace` of the configurations to observe.
   */
  observeAllByNamespace<Namespace extends Namespaces>(namespace: Namespace): Observable<Partial<NamespaceScoped<Namespace>>> {
    return this.query([where('data.namespace', '==', namespace), orderBy('order', 'asc')])
      .observe(true)
      .pipe(
        map((configurations) => {
          const result: Record<string, unknown[]> = {};

          for (const configuration of Object.values(configurations)) {
            if (result[configuration.data.scope] == null) {
              result[configuration.data.scope] = [];
            }

            result[configuration.data.scope].push(configuration);
          }

          return result as Partial<NamespaceScoped<Namespace>>;
        }),
      )
      .pipe(distinctUntilChanged())
      .pipe(shareReplay(1));
  }
}
