import { AbstractCollection } from '../../abstract/Collection';
import { AbstractDocument, Identifiable, Timestampable } from '../../abstract/Document';
import { Supplier, Suppliers } from '../Suppliers';
import type { IConfigurationLeadsStage } from './Configurations.types';
import { type ILead, ILeadReadState, ILeadRepliedState } from './Leads.types';
import {
  arrayUnion,
  deleteField,
  type PartialWithFieldValue,
  runTransaction,
  serverTimestamp,
  where,
  type WithFieldValue,
} from 'firebase/firestore';
import { splitEvery } from 'ramda';

@Identifiable
@Timestampable
export class Lead extends AbstractDocument<ILead> {
  readonly collections = {};

  get supplier() {
    return Suppliers._.getById(this.reference.parent.parent.id);
  }

  /**
   * Calculates the score of the lead from the weights of the different stage transitions.
   *
   * @param snapshot The `Lead` snapshot, optional.
   */
  async getScore(snapshot?: ILead) {
    /**
     * If the snapshot is not provided, fetch the latest snapshot.
     */
    if (snapshot == null) {
      snapshot = await this.get(true);
    }

    let result = 1;

    /**
     * Sort the stages by their timestamp and map them to their keys (IDs).
     */
    const stages = Object.entries(snapshot.stages)
      .sort((a, b) => a[1].toMillis() - b[1].toMillis())
      .map(([key]) => key);

    if (stages.length > 0) {
      const configurations = await this.supplier.Configurations.query<IConfigurationLeadsStage>([where('id', 'in', stages)]).get(true);

      for (const stage of stages) {
        if (configurations[stage]?.weight != null) {
          result *= configurations[stage].weight;
        }
      }
    }

    return result;
  }

  /**
   * Derives the read and replied state of the lead from the `read` and `replied` temporal booleans.
   *
   * @param snapshot The `Lead` snapshot, optional.
   */
  async getState(snapshot?: ILead): Promise<{ read: ILeadReadState; replied: ILeadRepliedState }> {
    /**
     * If the snapshot is not provided, fetch the latest snapshot.
     */
    if (snapshot == null) {
      snapshot = await this.get(true);
    }

    const result = {
      read: snapshot.actions.read === false ? ILeadReadState.Unread : ILeadReadState.Read,
      replied: snapshot.actions.replied === false ? ILeadRepliedState.NotReplied : ILeadRepliedState.Replied,
    };

    /**
     * If the lead has never been read, we return the "New" state.
     */
    if (snapshot.actions.read == null) {
      result.read = ILeadReadState.New;
    }

    return result;
  }

  /**
   * Returns a boolean indicating whether the lead has a (future) scheduled reply.
   *
   * @param snapshot The `Lead` snapshot, optional.
   */
  async getScheduled(snapshot?: ILead): Promise<boolean> {
    /**
     * If the snapshot is not provided, fetch the latest snapshot.
     */
    if (snapshot == null) {
      snapshot = await this.get(true);
    }

    if (snapshot.followUpAt) {
      return snapshot.followUpAt.toDate().getTime() > Date.now();
    }

    return false;
  }

  /**
   * Marks the lead as read, using the current timestamp.
   */
  markAsRead() {
    return this.setAction('read', serverTimestamp());
  }

  /**
   * Marks the lead as unread, preserving the `null` value if the lead has never been read.
   */
  markAsUnread() {
    return runTransaction(this.reference.firestore, async (transaction) => {
      const data = (await transaction.get(this.reference)).data();

      if (data.actions.read != null) {
        transaction.set(
          this.reference,
          {
            actions: {
              read: false,
            },
            updatedAt: serverTimestamp(),
          },
          {
            merge: true,
          },
        );
      }

      return true;
    });
  }

  /**
   * Sets a single action on the document.
   *
   * @param action The action to set.
   * @param value The value to set, can be either a timestamp or `false` (or `null` in case of "read").
   */
  setAction<Actions = ILead['actions'], Action extends keyof Actions = keyof Actions>(
    action: Action,
    value: WithFieldValue<Actions>[Action],
  ) {
    return this.setActions({
      [action]: value,
    });
  }

  /**
   * Sets multiple actions on the document.
   *
   * @param actions A key-value pair of actions to set. The values can be either timestamps or `false` (or `null` in case of "read").
   */
  setActions(actions: PartialWithFieldValue<ILead['actions']>) {
    return this.set(
      {
        actions,
      },
      true,
    );
  }

  /**
   * Set stage on the document.
   *
   * @param stageId The ID of the stage to be set.
   */
  setStage(stageId: ILead['stage']) {
    return this.set({
      stage: stageId,
      stages: {
        [stageId]: serverTimestamp(),
      },
      status: deleteField(), // Business requirement: `status` should be deleted when new stage is set
    });
  }
}

export class Leads extends AbstractCollection<Lead, ILead> {
  static path = 'leads';

  constructor(document: Supplier) {
    super(document.collection(Leads.path), Lead);
  }

  get supplier() {
    return Suppliers._.getById(this.reference.parent.id);
  }

  async getAll() {
    return await this.query().get(true);
  }

  /**
   * Set `assignee` by IDs
   * @param ids The IDs of the leads for which the `assignee` will be set.
   * @param assigneeUserId The user ID of the new `assignee`.
   */
  async setAssigneeByIds(ids: ILead['id'][], assigneeUserId: ILead['assignee']) {
    if (ids.length === 0 || !assigneeUserId) {
      return;
    }

    const batches = splitEvery(250, ids);

    for (const batch of batches) {
      this.begin();

      for (const id of batch) {
        await this.getById(id).set({
          assignee: assigneeUserId,
        });
      }

      await this.commit();
    }

    return;
  }

  /**
   * Set `label` by IDs
   * @param ids The IDs of the leads for which the `label` will be set.
   * @param labelId The new label ID.
   */
  async setLabelByIds(ids: ILead['id'][], labelId: ILead['labels'][number]) {
    if (ids.length === 0 || !labelId) {
      return;
    }

    const batches = splitEvery(250, ids);

    for (const batch of batches) {
      this.begin();

      for (const id of batch) {
        await this.getById(id).set({
          labels: arrayUnion(labelId),
        });
      }

      await this.commit();
    }

    return;
  }

  /**
   * Set `stage` by IDs
   * @param ids The IDs of the leads for which the `stage` will be set.
   * @param stageId The new stage ID.
   */
  async setStageByIds(ids: ILead['id'][], stageId: ILead['stage']) {
    if (ids.length === 0 || !stageId) {
      return;
    }

    const batches = splitEvery(250, ids);

    for (const batch of batches) {
      this.begin();

      for (const id of batch) {
        await this.getById(id).set({
          stage: stageId,
          stages: {
            [stageId]: serverTimestamp(),
          },
          status: deleteField(), // Business requirement: `status` should be deleted when new stage is set
        });
      }

      await this.commit();
    }

    return;
  }

  /**
   * Set `status` by IDs
   * @param ids The IDs of the leads for which the `status` will be set.
   * @param statusId The new status ID.
   */
  async setStatusByIds(ids: ILead['id'][], statusId: ILead['status']) {
    if (ids.length === 0 || !statusId) {
      return;
    }

    const batches = splitEvery(250, ids);

    for (const batch of batches) {
      this.begin();

      for (const id of batch) {
        await this.getById(id).set({
          status: statusId,
        });
      }

      await this.commit();
    }

    return;
  }

  /**
   * Set `read` by IDs
   * @param ids The IDs of the leads that will be marked as `read`.
   */
  async setReadByIds(ids: ILead['id'][]) {
    if (ids.length === 0) {
      return;
    }

    const batches = splitEvery(250, ids);

    for (const batch of batches) {
      this.begin();

      for (const id of batch) {
        await this.getById(id).set({
          actions: {
            read: serverTimestamp(),
          },
        });
      }

      await this.commit();
    }

    return;
  }

  /**
   * Set `not read` by IDs
   * @param ids The IDs of the leads that will be marked as `not read`.
   */
  async setNotReadByIds(ids: ILead['id'][]) {
    if (ids.length === 0) {
      return;
    }

    const batches = splitEvery(250, ids);

    for (const batch of batches) {
      this.begin();

      for (const id of batch) {
        await this.getById(id).set({
          actions: {
            read: false,
          },
        });
      }

      await this.commit();
    }

    return;
  }

  /**
   * Set `replied` by IDs
   * @param ids The IDs of the leads that will be marked as `replied`.
   */
  async setRepliedByIds(ids: ILead['id'][]) {
    if (ids.length === 0) {
      return;
    }

    const batches = splitEvery(250, ids);

    for (const batch of batches) {
      this.begin();

      for (const id of batch) {
        await this.getById(id).set({
          actions: {
            replied: serverTimestamp(),
          },
        });
      }

      await this.commit();
    }

    return;
  }

  /**
   * Set `not replied` by IDs
   * @param ids The IDs of the leads that will be marked as `not replied`.
   */
  async setNotRepliedByIds(ids: ILead['id'][]) {
    if (ids.length === 0) {
      return;
    }

    const batches = splitEvery(250, ids);

    for (const batch of batches) {
      this.begin();

      for (const id of batch) {
        await this.getById(id).set({
          actions: {
            replied: false,
          },
        });
      }

      await this.commit();
    }

    return;
  }

  /**
   * Set `starred` by IDs
   * @param ids The IDs of the leads that will be marked as `starred`.
   */
  async setStarredByIds(ids: ILead['id'][]) {
    if (ids.length === 0) {
      return;
    }

    const batches = splitEvery(250, ids);

    for (const batch of batches) {
      this.begin();

      for (const id of batch) {
        await this.getById(id).set({
          actions: {
            starred: serverTimestamp(),
          },
        });
      }

      await this.commit();
    }

    return;
  }

  /**
   * Set `not starred` by IDs
   * @param ids The IDs of the leads that will be marked as `not starred`.
   */
  async setNotStarredByIds(ids: ILead['id'][]) {
    if (ids.length === 0) {
      return;
    }

    const batches = splitEvery(250, ids);

    for (const batch of batches) {
      this.begin();

      for (const id of batch) {
        await this.getById(id).set({
          actions: {
            starred: false,
          },
        });
      }

      await this.commit();
    }

    return;
  }
}
