/* eslint-disable */
import type { firebase } from '../../../../firebase/firestore';

export interface ISupplierLedgerTransaction {
  /**
   * Ending Product Balance
   */
  balance: number;
  createdAt: firebase.firestore.Timestamp;
  id: string;
  metadata?: {
    [k: string]: any;
  };
  operation: ITransactionOperation;
  product: ISupplierProduct;
  units: number;
  updatedAt?: firebase.firestore.Timestamp;
  /**
   * User ID
   */
  user: string;
}

export enum ITransactionOperation {
  GIFT = 'gift',
  PURCHASE = 'purchase',
  ROLLBACK = 'rollback',
  SPENT = 'spent',
}
export enum ISupplierProduct {
  CONTACT_COUPLES = 'contactCouples',
  ENQUIRIES = 'enquiries',
}
