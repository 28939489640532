/* eslint-disable */
import type { CountryCodes, firebase } from '../../firebase/firestore';

export type WeekDay = 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun';
export type Month = number;
export type Season = 'autumn' | 'spring' | 'summer' | 'winter';

export interface ILead {
  /**
   * Supplier Actions
   */
  actions: {
    /**
     * Read Wedding Lead
     * Reset to `false` on incoming messagesm, `null` value represents a new lead.
     */
    read: null | (false | firebase.firestore.Timestamp);
    /**
     * Replied Wedding Lead
     * Reset to `false` on incoming messages.
     */
    replied: false | firebase.firestore.Timestamp;
    /**
     * Replied Wedding Lead for the first time
     */
    repliedFirst: false | firebase.firestore.Timestamp;
    /**
     * Revealed Wedding Lead
     */
    revealed: false | firebase.firestore.Timestamp;
    /**
     * Starred Wedding Lead
     */
    starred: false | firebase.firestore.Timestamp;
  };
  /**
   * Assigned Supplier User IDs
   */
  assignee?: string;
  createdAt: firebase.firestore.Timestamp;
  /**
   * Lead UUID (Wedding ID when Possible)
   */
  id: string;
  /**
   * Lead Intent
   *
   * @minItems 1
   */
  intent?: [ILeadIntentOption, ...ILeadIntentOption[]];
  /**
   * Next Follow-Up At: to be replaced soon by a new field with templates and follow-ups refactor
   */
  followUpAt?: firebase.firestore.Timestamp;
  /**
   * Next Follow-Up IDs: to be removed soon with templates and follow-ups refactor
   */
  followUps?: string[];
  labels?: string[];
  /**
   * The latest message, to or from the supplier.
   */
  message?: {
    /**
     * Author of the message (name).
     */
    author?: string;
    /**
     * Snippet of the message content.
     */
    content?: string;
    timestamp: firebase.firestore.Timestamp;
  };
  origin: {
    ip?: {
      /**
       * GeoIP Country Code
       */
      country: CountryCodes;
    };
    source: ILeadSource;
  };
  /**
   * Configuration from Leads:Stage
   */
  stage: string;
  /**
   * Latest Stage Transition Timestamps
   */
  stages: {
    [k: string]: firebase.firestore.Timestamp;
  };
  /**
   * Configuration from Leads:Status
   */
  status?: string;
  updatedAt?: firebase.firestore.Timestamp;
  wedding?: {
    original: ILeadWeddingOriginal;
    overrides?: ILeadWedding;
  };
}
/**
 * Wedding Lead Details
 */
export interface ILeadWeddingOriginal {
  budget?: null | number;
  contacts?: {
    email?: string;
    emailRecords?: MXRecords;
    phone?: string;
    website?: string;
  };
  date?: {
    d: number | WeekDay | null | WeekDay[];
    m: Month | Season | null | Month[];
    y: number | null;
  };
  guests?: {
    estimate?: number;
  };
  l10n?: {
    /**
     * ISO 3166-1 Alpha-2 Code
     */
    country?: CountryCodes;
    /**
     * ISO 4217 Code
     */
    currency?: string;
  };
  /**
   * @minItems 1
   */
  partners?: [string, ...string[]];
  user?: {
    /**
     * Wedding Lead User ID
     */
    id: string;
    /**
     * Wedding Lead User Photo Path
     */
    photoPath?: string;
  };
  /**
   * Wedding User IDs
   */
  users?: string[];
  supplier?: {
    /**
     * If and when the supplier was booked by the couple.
     */
    booked?: false | firebase.firestore.Timestamp;
    /**
     * If and when the supplier was enquired by the couple.
     */
    enquired?: false | firebase.firestore.Timestamp;
  };
}
/**
 * Email MX Records
 */
export interface MXRecords {
  /**
   * MX Priority Map
   */
  exchanges?: {
    [k: string]: number;
  };
  /**
   * Canonical Email Providers
   */
  providers?: string[];
}
/**
 * Wedding Lead Details
 *
 * This interface was referenced by `ILead`'s JSON-Schema
 * via the `definition` "wedding".
 */
export interface ILeadWedding {
  budget?: null | number;
  contacts?: {
    email?: string;
    emailRecords?: MXRecords;
    phone?: string;
    website?: string;
  };
  date?: {
    d: number | WeekDay | null | WeekDay[];
    m: Month | Season | null | Month[];
    y: number | null;
  };
  guests?: {
    estimate?: number;
  };
  l10n?: {
    /**
     * ISO 3166-1 Alpha-2 Code
     */
    country?: CountryCodes;
    /**
     * ISO 4217 Code
     */
    currency?: string;
  };
  /**
   * @minItems 1
   */
  partners?: [string, ...string[]];
  user?: {
    /**
     * Wedding Lead User ID
     */
    id: string;
    /**
     * Wedding Lead User Photo Path
     */
    photoPath?: string;
  };
  /**
   * Wedding User IDs
   */
  users?: string[];
}

/**
 * Lead Intent Option
 *
 * This interface was referenced by `ILead`'s JSON-Schema
 * via the `definition` "intentOption".
 */
export enum ILeadIntentOption {
  availability = 'availability',
  brochure = 'brochure',
  dates = 'dates',
  info = 'info',
  other = 'other',
  pricing = 'pricing',
  quote = 'quote',
  requestFastResponse = 'requestFastResponse',
  showround = 'showround',
  videotour = 'videotour',
}
export enum ILeadSource {
  Booking = 'booking',
  Concierge = 'concierge',
  PowerMatch = 'powermatch',
  Standard = 'standard',
  Widget = 'widget',
}
/**
 * (Derived) Lead Read State
 *
 * This interface was referenced by `ILead`'s JSON-Schema
 * via the `definition` "readState".
 */
export enum ILeadReadState {
  New = 'New',
  Read = 'Read',
  Unread = 'Unread',
}
/**
 * (Derived) Lead Replied State
 *
 * This interface was referenced by `ILead`'s JSON-Schema
 * via the `definition` "repliedState".
 */
export enum ILeadRepliedState {
  Replied = 'Replied',
  NotReplied = 'Not Replied',
}
